<template>
  <div class="personal_audit">
    <div class="personal_box">
      <Tab @tabChang="tabChang"
           :tabList="tabList"
           :tabcurrent="tabcurrent">
      </Tab>
      <Serch @searchForm="searchForm"
             @onsearch='getAuditList'></Serch>
      <Table :list="tableList"
             :total="total"
             ref="table"
             :tabcurrent='tabcurrent'
             :currentPage="page.page"
             @getAuditList='getList'
             @handleSizeChange="handleSizeChange"
             @handleCurrentChange='handleCurrentChange'></Table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, defineComponent, toRefs, reactive, getCurrentInstance, onMounted, ref } from 'vue'
const Serch = defineAsyncComponent(() => import('./component/serch.vue'))
const Tab = defineAsyncComponent(() => import('./component/tab.vue'))
const Table = defineAsyncComponent(() => import('./component/table.vue'))
export default defineComponent({
  components: {
    Serch,
    Tab,
    Table
  },

  setup (props, context) {
    const table = ref('table')
    const { proxy } = getCurrentInstance()
    const data = reactive({ // 定义变量
      tabcurrent: '0',
      tabList: [
        { value: '0', label: '待审核' },
        { value: '1', label: '审核通过' },
        { value: '2', label: '审核未通过' }
      ],
      tableList: [],
      formData: {
        user_name: '',
        date: []
      },
      page: {
        page: 1,
        limit: 30
      },
      total: 0,
      detailData: {},
      showModal: false
    })
    // 切换标签
    const tabChang = (val) => {
      data.tabcurrent = val
      data.tableList = []
      data.page.page = 1
      getAuditList()
    }
    const searchForm = (obj) => {
      data.formData.user_name = obj.user_name
      data.formData.date = obj.date
      getAuditList()
      // 搜索数据
    }
    const handleSizeChange = (val) => {
      data.page.limit = val
      getAuditList()
    }
    const handleCurrentChange = (val) => {
      data.page.page = val
      getAuditList()
    }
    onMounted(() => {
      getAuditList()
    })
    const getList = () => {
      if (proxy.$api.getAuditList) {
        getAuditList()
      }
    }
    const getAuditList = async () => {
      try {
        let startTime = ''
        let endTime = ''
        if (data.formData.date && data.formData.date[0]) {
          startTime = proxy.$moment(data.formData.date[0]).unix()
          endTime = proxy.$moment(data.formData.date[1]).unix()
          //修正结束时间
          // if (startTime === endTime) {
          endTime = endTime + 86399
          // }
        }
        const sd = await proxy.$api.getAuditList(
          {
            start_time: startTime,
            end_time: endTime,
            user_name: data.formData.user_name,
            type: 2,
            cert_status: data.tabcurrent,
            page: data.page.page,
            limit: data.page.limit
          })
        sd.data.list.forEach(item => {
          item.created_at = proxy.$moment(item.created_at * 1000).format('YYYY-MM-DD')
        })
        data.tableList = sd.data.list
        data.total = sd.data.total
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error'
        })
      }
    }
    return {
      ...toRefs(data),
      tabChang,
      searchForm,
      getAuditList,
      handleCurrentChange,
      handleSizeChange,
      getList,
      table
    }
  }
})
</script>

<style lang="scss" scoped>
.personal_audit {
  padding: 10px;
  .personal_box {
    background: #fff;
    border-radius: 6px;
  }
}
</style>
